export enum JobScreenerQuestionPresets {
  HAS_DRIVERS_LICENSE_AND_ACCESS_TO_CAR = "HAS_DRIVERS_LICENSE_AND_ACCESS_TO_CAR",
}

export type JobScreenerQuestionPopulatedValue =
  | {
      type: "YES_OR_NO";
      question: string;
    }
  | {
      type: JobScreenerQuestionPresets;
    };

export type JobScreenerQuestionValue =
  | {
      type: null; // not set yet
    }
  | JobScreenerQuestionPopulatedValue;
