import { Control, FieldValues, Path, useFormState, get } from "react-hook-form";

import { TextField } from "@rewards-web/shared/components/text-field";

interface EmployeeExternalIdFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
}

export function EmployeeExternalIdField<T extends FieldValues>({
  control,
  name,
}: EmployeeExternalIdFieldProps<T>) {
  const formState = useFormState({ control, name });

  return (
    <TextField
      label="External employee ID (optional)"
      error={get(formState.errors, name)}
      disableAutocomplete
      {...control.register(name)}
    />
  );
}
