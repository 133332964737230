import { SurveyQuestionType } from "@rewards-web/shared/graphql-types";

export type SurveyQuestionTypeSupportedByUI = Extract<
  SurveyQuestionType,
  | SurveyQuestionType.MeaningfulRecognition
  | SurveyQuestionType.ManageableWorkload
  | SurveyQuestionType.ReliableTransportation
  | SurveyQuestionType.LeadershipSupport
  | SurveyQuestionType.Resources
  | SurveyQuestionType.Nps
>;

export const PULSE_SURVEY_QUESTIONS: SurveyQuestionTypeSupportedByUI[] = [
  SurveyQuestionType.MeaningfulRecognition,
  SurveyQuestionType.ManageableWorkload,
  SurveyQuestionType.ReliableTransportation,
  SurveyQuestionType.LeadershipSupport,
  SurveyQuestionType.Resources,
  SurveyQuestionType.Nps,
];
