import {
  JobPostingFieldConfigurationInput,
  JobPostingFieldType,
} from "@rewards-web/shared/graphql-types";

import { JobScreenerQuestionValue } from "../typings";
import { serializeScreenerQuestions } from "./serialize-screener-questions";

export function serializeJobFieldConfiguration(
  screenerQuestions: JobScreenerQuestionValue[]
): JobPostingFieldConfigurationInput[] {
  return [
    { type: JobPostingFieldType.FirstName },
    { type: JobPostingFieldType.LastName },
    {
      type: JobPostingFieldType.PreferredName,
      optional: true,
    },
    { type: JobPostingFieldType.Email },
    { type: JobPostingFieldType.PhoneNumber },
    { type: JobPostingFieldType.ResidingCity },
    ...serializeScreenerQuestions(screenerQuestions),
  ];
}
