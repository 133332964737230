import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";

import { PageLayout } from "../../../shared/components/page-layout";
import { ForbiddenErrorPage } from "../../../shared/modules/permissions/components/forbidden-error-page";
import { SurveysPageContents } from "./page-contents";

const MAX_CONTENT_WIDTH = 1400;

export function SurveysPage(): JSX.Element {
  // TODO: Replace with permissions, use AuthorizedAdminPageWrapper
  const surveysPageEnabled = useFeatureFlag(
    "admin-app-surveys-page-enabled-temp"
  );

  if (!surveysPageEnabled) {
    return <ForbiddenErrorPage />;
  }

  return (
    <PageLayout maxContentWidth={MAX_CONTENT_WIDTH}>
      <SurveysPageContents />
    </PageLayout>
  );
}
