/** @jsxImportSource @emotion/react */
import { ClassNames } from "@emotion/react";
import { lighten, LinearProgress } from "@material-ui/core";

export interface ProgressBarProps {
  value: number;
  whiteBar?: boolean;
  borderRadius?: number;
}

export function ProgressBar({
  value,
  whiteBar = false,
  borderRadius = 5,
}: ProgressBarProps): JSX.Element {
  return (
    <ClassNames>
      {({ css, theme }) => (
        <LinearProgress
          css={css`
            min-width: 100%;
          `}
          classes={{
            root: css`
              height: 10px;
              border-radius: ${borderRadius}px;
            `,
            colorPrimary: css`
              background-color: ${whiteBar
                ? lighten(theme.palette.primary.main, 0.1)
                : "#fff"};
            `,
            bar: css`
              border-radius: 5;
              background-color: ${whiteBar
                ? theme.palette.background.default
                : theme.palette.primary.main};
            `,
          }}
          variant="determinate"
          value={value}
        ></LinearProgress>
      )}
    </ClassNames>
  );
}
