/** @jsxImportSource @emotion/react */
import { ApolloError } from "@apollo/client";
import { css } from "@emotion/react";
import {
  faChevronLeft,
  faInfoCircle,
  faPlus,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zonedTimeToUtc } from "date-fns-tz";
import { isNull } from "lodash";
import { useState } from "react";
import {
  Controller,
  ValidateResult,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { DateField } from "@rewards-web/shared/components/date-field";
import { Form } from "@rewards-web/shared/components/form";
import { TextField } from "@rewards-web/shared/components/text-field";
import { TimeField } from "@rewards-web/shared/components/time-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { useNavigationBlockingPrompt } from "@rewards-web/shared/hooks/use-navigation-blocking-prompt";
import { getCharactersRemainingText } from "@rewards-web/shared/lib/characters-remaining-text";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { megabytesToBytes } from "@rewards-web/shared/lib/megabytes-to-bytes";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { VideoField } from "../../../../shared/components/video-field";
import {
  getPrizeTierName,
  validateNumberOfWinners,
  validatePrizeValue,
} from "../lib";
import { AnnouncementVideoInfoCard } from "./announcement-video-info-card";
import { useScheduleDrawCreatePresignedVideoUploadUrlMutation } from "./create-presigned-video-upload-url.generated";
import { serializeDateAndTime } from "./lib";
import { useScheduleDrawMarkVideoUploadedMutation } from "./mark-video-uploaded.generated";
import { useScheduleDrawV2Mutation } from "./schedule-draw-v2.generated";
import { useScheduleNewDrawPageDataQuery } from "./schedule-new-draw-page-data.generated";

const MAX_NAME_LENGTH = 70;
const INTEGERS_REGEX = /^([+-]?[1-9]\d*|0)$/;
const MIN_NUM_WINNERS = 1;
const MAX_NUM_ULTIMATE_WINNERS = 10;
const MAX_NUM_SILVER_WINNERS = 50;
const MAX_NUM_BONUS_WINNERS = 300;

const MIN_POINTS = 100;
const MAX_POINTS = 1_000_000;
const MAX_VIDEO_SIZE_MB = 100;

const SUPPORTED_VIDEO_MIME_TYPES = ["video/mp4"] as const;
export type VideoFieldSupportedMimeType = typeof SUPPORTED_VIDEO_MIME_TYPES[number];
const SUPPORTED_VIDEO_EXTENSIONS = SUPPORTED_VIDEO_MIME_TYPES.map((type) =>
  type.replace("video/", ".")
).join(", ");

interface ScheduleNewDrawFormValues {
  name: string;
  startDate: string; // YYYY-MM-DD
  startTime: string; // HH:MM
  endDate: string; // YYYY-MM-DD
  endTime: string; // HH:MM
  prizes: Array<{
    prizePointValue: string;
    numberOfWinners: string;
  }>;
  videoUrl: File | null;
}

export function ScheduleNewDrawV2PageContents() {
  const track = useTrack();
  const query = useScheduleNewDrawPageDataQuery({ onError: reportError });
  const snackbar = useSnackbar();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [videoId, setVideoId] = useState<string | null>(null);
  const navigate = useNavigate();
  const videoAnnouncementFlagEnabled = useFeatureFlag(
    "rewards-draw-video-announcement-enabled-temp"
  );
  const [
    createPresignedVideoUploadUrlMutation,
  ] = useScheduleDrawCreatePresignedVideoUploadUrlMutation();
  const [
    markVideoUploadedMutation,
  ] = useScheduleDrawMarkVideoUploadedMutation();

  const handleCancel = () => {
    navigate(-1);
  };

  const handleAddRow = () => {
    if (prizeFields.length < 3) {
      appendPrize({ prizePointValue: "", numberOfWinners: "" });
    }
  };

  const form = useForm<ScheduleNewDrawFormValues>({
    defaultValues: {
      name: "",
      startDate: "",
      startTime: "00:00",
      endDate: "",
      endTime: "23:59",
      prizes: [{ prizePointValue: "", numberOfWinners: "" }],
      videoUrl: null,
    },
  });

  const uploadFile = async () => {
    setIsUploading(true);

    const video = form.getValues("videoUrl");

    if (isNull(video)) {
      form.setError("videoUrl", {
        type: "custom",
        message: "We've encountered an unexpected error. Please try again.",
      });

      setIsUploading(false);

      return;
    }

    const createVideoUploadPresignedUrl = await createPresignedVideoUploadUrlMutation(
      {
        variables: {
          originalFilename: video!.name,
        },
      }
    );

    try {
      const presignedVideoUploadUrl =
        createVideoUploadPresignedUrl.data?.createPresignedVideoUploadUrl
          .presignedVideoUploadUrl;

      const videoId =
        createVideoUploadPresignedUrl.data?.createPresignedVideoUploadUrl.video
          .id;

      if (!presignedVideoUploadUrl) {
        throw new Error("Failed to get presigned video upload URL");
      }

      if (!videoId) {
        throw new Error("Failed to get video ID");
      }

      const response = await fetch(`${presignedVideoUploadUrl!}`, {
        body: video,
        method: "PUT",
        headers: {
          "Content-Length": String(video!.size),
          "Content-Type": video!.type,
        },
      });

      // Fetch doesn't throw for HTTP error codes, so we need to check for them
      // Ref: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#checking_that_the_fetch_was_successful
      if (!response.ok) {
        throw new Error("Failed to upload video");
      }

      await markVideoUploadedMutation({
        variables: { videoId },
      });

      // When successful, set the video ID for later use when scheduling a draw
      setVideoId(videoId);
    } catch (error) {
      reportError(error);

      form.setError("videoUrl", {
        type: "custom",
        message:
          "We've encountered an error while uploading your video. Please try again.",
      });
    } finally {
      setIsUploading(false);
      return;
    }
  };

  useNavigationBlockingPrompt(
    "Are you sure you want to leave this page? You will lose all unsaved changes.",
    form.formState.isDirty
  );

  const drawName = useWatch({ control: form.control, name: "name" });
  const prizesList = useWatch({
    control: form.control,
    name: "prizes",
  });

  const {
    fields: prizeFields,
    append: appendPrize,
    remove: removePrize,
  } = useFieldArray({
    control: form.control,
    name: "prizes",
  });

  const [scheduleDrawV2] = useScheduleDrawV2Mutation();

  const maximumWinnersNumber = (index: number): number => {
    switch (index) {
      case 0:
        return MAX_NUM_ULTIMATE_WINNERS;
      case 1:
        return MAX_NUM_SILVER_WINNERS;
      case 2:
        return MAX_NUM_BONUS_WINNERS;
      default:
        return MAX_NUM_ULTIMATE_WINNERS;
    }
  };
  const handleSubmit = async (values: ScheduleNewDrawFormValues) => {
    try {
      let res = await scheduleDrawV2({
        variables: {
          name: values.name,
          start: zonedTimeToUtc(
            serializeDateAndTime(values.startDate, values.startTime),
            query.data!.getMyRewardsOrganization.timezone
          ),
          end: zonedTimeToUtc(
            serializeDateAndTime(values.endDate, values.endTime),
            query.data!.getMyRewardsOrganization.timezone
          ),
          prizeStructure: values.prizes.map((row) => ({
            numWinners: Number(row.numberOfWinners),
            pointValue: Number(row.prizePointValue),
          })),
          announcementVideoId: videoId,
        },
      });

      form.reset(); // this is needed for the navigation block prompt to clear

      navigate("/draw");

      track("Scheduled draw", { drawId: res.data?.scheduleDrawV2.id });

      snackbar.show({
        severity: "success",
        message: `Success! Draw ${values.name} has been created.`,
      });
    } catch (error) {
      if (
        error instanceof ApolloError &&
        error.graphQLErrors[0].extensions?.draw_error_code ===
          "RANGE_OVERLAPS_WITH_EXISTING_DRAW"
      ) {
        snackbar.show({
          severity: "error",
          message:
            "Oops! Start or end date must not overlap with another scheduled draw.",
        });
      } else {
        snackbar.show({
          severity: "error",
          message: `Oops! There was a technical error while scheduling ${values.name} draw. Please try again.`,
        });
        reportError(error);
      }
    }
  };

  const handleErrorsOnSubmission = () => {
    snackbar.show({
      severity: "error",
      message: "Oops! There are errors with the draw you've tried to schedule.",
    });
  };

  return (
    <Form
      onSubmit={form.handleSubmit(handleSubmit, handleErrorsOnSubmission)}
      submitting={form.formState.isSubmitting}
    >
      <Button
        variant="text"
        startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
        label="Back"
        width="auto"
        typographyVariant="body"
        onClick={() => {
          handleCancel();
        }}
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      />

      <Typography
        variant="h1"
        color="textPrimary"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(3)};
        `}
      >
        Schedule a draw
      </Typography>

      <Card
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(4)};
          margin-bottom: ${theme.spacing(3)};
        `}
      >
        <Typography
          variant="h5"
          component="h2"
          color="textPrimary"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(1)};
          `}
        >
          Set up
        </Typography>

        <Typography
          variant="body"
          color="grey.800"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          Draws are defaulted to go live at 12 AM and end at 11:59 PM within
          your agency's set time zone.
        </Typography>

        <div
          css={(theme: AppTheme) => css`
            max-width: 500px;
            margin-bottom: ${theme.spacing(3)};
          `}
        >
          <TextField
            label="Draw name"
            autoFocus
            disableAutocomplete
            error={form.formState.errors.name}
            helperText={getCharactersRemainingText(drawName, MAX_NAME_LENGTH)}
            {...form.register("name", {
              required: "Draw name is required.",
              validate: (value) => {
                if (value && value.length > MAX_NAME_LENGTH) {
                  return getCharactersRemainingText(value, MAX_NAME_LENGTH);
                }
              },
            })}
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(1)};
            `}
          />

          <div
            css={(theme: AppTheme) => css`
              display: grid;
              grid-template-columns: 6.5fr 3.5fr;
              grid-gap: ${theme.spacing(2)};
              margin-bottom: ${theme.spacing(1)};
            `}
          >
            <Controller
              control={form.control}
              name="startDate"
              rules={{
                required: "Start date is required.",
              }}
              render={({ field, fieldState }) => (
                <DateField
                  {...field}
                  onChange={(...args) => {
                    field.onChange(...args);
                    if (form.formState.isSubmitted) {
                      setTimeout(() => form.trigger("endDate"), 0);
                      setTimeout(() => form.trigger("startTime"), 0);
                    }
                  }}
                  label="Start date"
                  error={fieldState.error}
                  minDate={new Date()}
                />
              )}
            />
            <Controller
              control={form.control}
              name="startTime"
              rules={{
                required: "Start time is required.",
                validate: (value) => {
                  const { startDate } = form.getValues();

                  if (value && startDate) {
                    const now = new Date();
                    const start = serializeDateAndTime(startDate, value);

                    if (start < now) {
                      return "Start time must be in the future.";
                    }
                  }
                },
              }}
              render={({ field, fieldState }) => (
                <TimeField
                  {...field}
                  label="Start time"
                  error={fieldState.error}
                />
              )}
            />
          </div>

          <div
            css={(theme: AppTheme) => css`
              display: grid;
              grid-template-columns: 6.5fr 3.5fr;
              grid-gap: ${theme.spacing(2)};
            `}
          >
            <Controller
              control={form.control}
              name="endDate"
              rules={{
                required: "End date is required.",
                validate: (value) => {
                  const { startDate } = form.getValues();

                  if (value < startDate) {
                    return "End date must be after the start date.";
                  }

                  if (value === startDate) {
                    return "Draw must be a minimum of 1 day.";
                  }
                },
              }}
              render={({ field, fieldState }) => (
                <DateField
                  {...field}
                  label="End date"
                  error={fieldState.error}
                  minDate={new Date()}
                />
              )}
            />
            <Controller
              control={form.control}
              name="endTime"
              rules={{
                required: "End time is required.",
              }}
              render={({ field, fieldState }) => (
                <TimeField
                  {...field}
                  label="End time"
                  error={fieldState.error}
                />
              )}
            />
          </div>
        </div>

        <Alert
          severity="info"
          title="Notifications"
          message="Caregivers will receive an email and/or SMS during day time hours to start completing tasks to earn tickets."
        />
      </Card>
      <Card
        css={(theme: AppTheme) => css`
          padding: ${theme.spacing(4)};
          margin-bottom: ${theme.spacing(3)};
        `}
      >
        <Typography
          variant="h5"
          component="h2"
          color="textPrimary"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(3)};
          `}
        >
          Prizes & Winners
        </Typography>

        <Typography
          variant="body"
          color="grey.800"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          Each draw can have multiple winners, each eligible for a designated
          prize.
        </Typography>
        {prizeFields.map((field, index) => {
          return (
            <div
              key={index}
              css={(theme: AppTheme) => css`
                display: grid;
                grid-template-columns: 1.5fr 3.5fr 3.5fr 1fr;
                align-items: center;
                grid-gap: ${theme.spacing(2)};
              `}
            >
              <Typography
                variant="body"
                css={(theme: AppTheme) => css`
                  margin-bottom: ${theme.spacing(3)};
                `}
              >
                {getPrizeTierName(index)}
              </Typography>

              <TextField
                key={field.id}
                type="text"
                label="Prize value per winner (in points)"
                disableAutocomplete
                endAdornment={
                  query.data &&
                  prizesList[index] &&
                  prizesList[index].prizePointValue &&
                  prizesList[index].prizePointValue.match(INTEGERS_REGEX) ? (
                    <Typography
                      variant="body"
                      fontWeight={600}
                      color="grey.800"
                    >
                      {formatDollars(
                        Number(prizesList[index].prizePointValue) /
                          query.data.getMyRewardsOrganization.pointsPerDollar
                      )}
                    </Typography>
                  ) : undefined
                }
                error={form.formState.errors?.prizes?.[index]?.prizePointValue}
                {...form.register(`prizes.${index}.prizePointValue`, {
                  required: true,
                  validate: (value) =>
                    validatePrizeValue(
                      value,
                      index,
                      INTEGERS_REGEX,
                      MIN_POINTS,
                      MAX_POINTS,
                      prizesList
                    ),
                })}
                InputProps={{
                  endAdornment: query.data &&
                    prizeFields[index].prizePointValue &&
                    prizeFields[index].prizePointValue.match(
                      INTEGERS_REGEX
                    ) && (
                      <Typography
                        variant="body"
                        fontWeight={600}
                        color="grey.800"
                      >
                        {formatDollars(
                          Number(prizeFields[index].prizePointValue) /
                            query.data.getMyRewardsOrganization.pointsPerDollar
                        )}
                      </Typography>
                    ),
                }}
              />
              <TextField
                type="text"
                label="Number of winners"
                disableAutocomplete
                error={form.formState.errors?.prizes?.[index]?.numberOfWinners}
                helperText={
                  <>
                    <FontAwesomeIcon icon={faInfoCircle} size="1x" /> Maxiumum
                    of {maximumWinnersNumber(index)}
                  </>
                }
                {...form.control.register(`prizes.${index}.numberOfWinners`, {
                  required: true,
                  validate: (value) =>
                    validateNumberOfWinners(
                      value,
                      index,
                      INTEGERS_REGEX,
                      MIN_NUM_WINNERS,
                      MAX_NUM_ULTIMATE_WINNERS,
                      MAX_NUM_SILVER_WINNERS,
                      MAX_NUM_BONUS_WINNERS
                    ),
                })}
              />
              {index > 0 && (
                <Button
                  variant="text"
                  startIcon={<FontAwesomeIcon icon={faTrash} />}
                  label=""
                  width="auto"
                  typographyVariant="body"
                  onClick={() => removePrize(index)}
                  css={(theme: AppTheme) => css`
                    margin-bottom: ${theme.spacing(3)};
                  `}
                />
              )}
            </div>
          );
        })}
        {prizeFields.length < 3 && (
          <Button
            variant="text"
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            label="Add another prize"
            width="auto"
            typographyVariant="body"
            onClick={handleAddRow}
            css={(theme: AppTheme) => css`
              width: 200px;
              height: fit-content;
              margin-bottom: ${theme.spacing(2)};
              float: left;
              border: solid 1px black;
              border-radius: ;
            `}
          />
        )}
      </Card>

      {videoAnnouncementFlagEnabled && (
        <Card
          css={(theme: AppTheme) => css`
            padding: ${theme.spacing(4)};
            margin-bottom: ${theme.spacing(3)};
          `}
        >
          <Typography
            variant="h5"
            component="h2"
            color="textPrimary"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(3)};
            `}
          >
            Announcement Video
            <Typography
              variant="h4"
              component="span"
              color="textPrimary"
              css={(theme: AppTheme) => css`
                margin-bottom: ${theme.spacing(3)};
              `}
            >
              {" "}
              (optional)
            </Typography>
          </Typography>
          <Typography
            variant="body"
            color="textPrimary"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(3)};
            `}
          >
            Boost engagement and excitement with a dynamic draw supplemented by
            a video!
          </Typography>

          <div
            css={css`
              max-width: 800px;
            `}
          >
            <Controller
              control={form.control}
              name="videoUrl"
              rules={{
                validate: (
                  value: File | string | null
                ): ValidateResult | undefined => {
                  if (value instanceof File) {
                    if (value.size > megabytesToBytes(MAX_VIDEO_SIZE_MB)) {
                      return `The video file should not exceed ${MAX_VIDEO_SIZE_MB} megabytes in size`;
                    }
                    if (
                      !SUPPORTED_VIDEO_MIME_TYPES.includes(
                        value.type as VideoFieldSupportedMimeType
                      )
                    ) {
                      return `Please make sure you are uploading a ${SUPPORTED_VIDEO_EXTENSIONS} file`;
                    }
                  }
                },
              }}
              render={({ field, fieldState }) => (
                <VideoField
                  {...field}
                  error={fieldState.error}
                  height="600px"
                  label="Video"
                  uploading={isUploading}
                  onChange={(...args) => {
                    field.onChange(...args);

                    track(
                      "Admin tried uploading announcement video for a draw",
                      {
                        organizationId: query.data?.getMyRewardsOrganization.id,
                        fileType:
                          field.value instanceof File
                            ? field.value.type
                            : "unknown",
                      }
                    );

                    form
                      .trigger("videoUrl", { shouldFocus: true })
                      .then((value) => {
                        if (value) {
                          uploadFile();
                        } else {
                          // Report error on Sentry for prompt handling
                          reportError(
                            new Error(
                              `Admin ${
                                query.data?.getMyRewardsAdminUser?.id
                              } tried uploading announcement video for a draw but validation failed, fileType: ${
                                field.value instanceof File
                                  ? field.value.type
                                  : "unknown"
                              }, fileSize: ${
                                field.value instanceof File
                                  ? field.value.size
                                  : "unknown"
                              }`
                            )
                          );
                        }
                      });
                  }}
                  onVideoReset={() => {
                    track("Admin removed video for a draw", {
                      organizationId: query.data?.getMyRewardsOrganization.id,
                    });
                    form.resetField("videoUrl");
                  }}
                />
              )}
            />

            <AnnouncementVideoInfoCard />
          </div>
        </Card>
      )}

      <div
        css={(theme: AppTheme) => css`
          float: right;
          display: flex;
          gap: ${theme.spacing(1)};
          padding-bottom: ${theme.spacing(3)};
        `}
      >
        <Button
          label="Cancel"
          variant="text"
          size="medium"
          onClick={() => {
            handleCancel();
          }}
        />
        <Button label="Save" color="primary" size="medium" type="submit" />
      </div>
    </Form>
  );
}
