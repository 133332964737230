import { ApolloError } from "@apollo/client";
import { compact } from "lodash";
import { useCallback, useMemo } from "react";

import { RewardsOrganizationOnboardingStepName } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";

import { useEmployeeAutoSyncAvailableQuery } from "../../../../shared/modules/employee-auto-sync/hooks";
import { OnboardingStepsSupportedByUI } from "../constants";
import { useMarkOnboardingStepCompletedMutation } from "./mark-step-completed.generated";
import { useOnboardingStateQuery } from "./onboarding-state.generated";

interface UseOnboardingStateParams {
  /**
   * If true, always loads fresh data.
   * Otherwise, returns the cached data (if any).
   */
  loadFreshData?: boolean;
}

interface UseOnboardingStateReturnValue {
  error: ApolloError | undefined;
  loading: boolean;
  launched: boolean;
  completedSteps: Set<OnboardingStepsSupportedByUI>;
  orderedSteps: OnboardingStepsSupportedByUI[];
  hasOnboardingStep(
    step: RewardsOrganizationOnboardingStepName
  ): step is OnboardingStepsSupportedByUI;

  /**
   * Makes a request to the server to mark the onboarding step as completed.
   *
   */
  recordCompletedOnboardingStep(
    step: RewardsOrganizationOnboardingStepName
  ): Promise<void>;
}

export function useOnboardingState({
  loadFreshData,
}: UseOnboardingStateParams = {}): UseOnboardingStateReturnValue {
  const onboardingStateQuery = useOnboardingStateQuery({
    fetchPolicy: loadFreshData ? "network-only" : "cache-first",
    nextFetchPolicy: "cache-first",
    onError: reportError,
  });
  const autoSyncAvailableQuery = useEmployeeAutoSyncAvailableQuery({
    fetchPolicy: "cache-first",
  });

  const orderedSteps: OnboardingStepsSupportedByUI[] = useMemo(
    () =>
      compact([
        RewardsOrganizationOnboardingStepName.Intro,
        RewardsOrganizationOnboardingStepName.YourProfile,
        (() => {
          if (!autoSyncAvailableQuery.data) {
            return null; // while loading
          }

          if (autoSyncAvailableQuery.data.enabled) {
            return RewardsOrganizationOnboardingStepName.EmployeeAutoSync;
          }

          return RewardsOrganizationOnboardingStepName.CaregiverInvites;
        })(),
        RewardsOrganizationOnboardingStepName.ReferralStructure,
        RewardsOrganizationOnboardingStepName.ConfigureJobs,
        RewardsOrganizationOnboardingStepName.JobSetting,
        RewardsOrganizationOnboardingStepName.BookKickOff,
      ]),
    [autoSyncAvailableQuery.data]
  );

  const hasOnboardingStep = useCallback(
    (
      step: RewardsOrganizationOnboardingStepName
    ): step is OnboardingStepsSupportedByUI =>
      orderedSteps.includes(step as OnboardingStepsSupportedByUI),
    [orderedSteps]
  );

  const [
    _recordCompletedOnboardingStep,
  ] = useMarkOnboardingStepCompletedMutation({
    onError: reportError,
  });

  // this code is in a useCallback using the stale mutation
  // because it otherwise causes an infinite loop in the story
  const recordCompletedOnboardingStep = useCallback<
    UseOnboardingStateReturnValue["recordCompletedOnboardingStep"]
  >(async (step) => {
    await _recordCompletedOnboardingStep({
      variables: {
        step,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(
    (): UseOnboardingStateReturnValue => ({
      error: onboardingStateQuery.error,
      loading: !onboardingStateQuery.data,
      launched:
        onboardingStateQuery.data?.getMyRewardsOrganization.launched === true,
      completedSteps: new Set(
        (
          onboardingStateQuery.data?.getMyRewardsOrganization.onboardingState
            .completedSteps ?? []
        ).filter(hasOnboardingStep)
      ),
      orderedSteps,
      recordCompletedOnboardingStep,
      hasOnboardingStep: hasOnboardingStep,
    }),
    [
      onboardingStateQuery,
      recordCompletedOnboardingStep,
      orderedSteps,
      hasOnboardingStep,
    ]
  );
}
