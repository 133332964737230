/** @jsxImportSource @emotion/react */
import { ClassNames } from "@emotion/react";
import { darken, FormHelperText, OutlinedInput } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { forwardRef } from "react";

import { ControlledFormFieldProps, StylableProps } from "../../types";
import { useFormControlContext } from "../form/form-control";

export interface ValueLabel {
  label: string;
  value: string;
}

export interface SelectFieldProps
  extends StylableProps,
    ControlledFormFieldProps {
  label: String;
  options: ValueLabel[];
  disabled?: boolean;
  readOnly?: boolean;
  onClick?(): void;
  width?: "full" | "auto";
  hideLabel?: boolean;
}

export const SelectField = forwardRef(
  (
    {
      options,
      label,
      name,
      className,
      value,
      onChange,
      onBlur,
      width = "auto",
      disabled = false,
      readOnly: readOnlyProp,
      error,
      onClick,
      hideLabel,
    }: SelectFieldProps,
    ref?: React.Ref<HTMLTextAreaElement | HTMLInputElement>
  ): JSX.Element => {
    const formControlContext = useFormControlContext();

    const readOnly = readOnlyProp || formControlContext.readOnly;

    return (
      <ClassNames>
        {({ css, cx, theme }) => (
          <FormControl
            variant="outlined"
            error={!!error}
            className={css`
              margin-bottom: ${theme.spacing(1)};
              ${width === "full" &&
              css`
                width: 100%;
              `}
              .custom-notched-outline {
                border-color: ${theme.palette.divider};
                border-radius: ${theme.spacing(1)};
              }

              &:hover:not(.Mui-focused):not(.Mui-error)
                .custom-notched-outline {
                border-color: ${darken(theme.palette.divider, 0.15)};
              }

              & .custom-input-root.Mui-disabled .custom-notched-outline,
              .custom-notched-outline {
                border-color: ${theme.palette.divider};
              }

              & .Mui-focused .custom-notched-outline {
                border-width: 1px;
              }
            `}
          >
            {!hideLabel && <InputLabel>{label}</InputLabel>}
            <Select
              readOnly={readOnly}
              input={
                <OutlinedInput
                  label={label}
                  notched={hideLabel ? false : undefined}
                  classes={{
                    root: "custom-input-root",
                    notchedOutline: "custom-notched-outline",
                  }}
                />
              }
              classes={{
                root: css`
                  background-color: #fff;
                  padding: 20px 14px;
                  height: 0.8em;

                  &:focus {
                    background-color: #fff;
                  }
                `,
              }}
              className={cx(className, readOnly ? "Mui-disabled" : null)}
              id={name}
              disabled={disabled || formControlContext.submitting}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              onClick={onClick}
              ref={ref}
              label={label}
            >
              {options.map((option) => {
                return <MenuItem value={option.value}>{option.label}</MenuItem>;
              })}
            </Select>
            <FormHelperText error={!!error}>
              {error ? error.message : " "}
            </FormHelperText>
          </FormControl>
        )}
      </ClassNames>
    );
  }
);
