import { JobPostingFieldType } from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";

import {
  JobScreenerQuestionPopulatedValue,
  JobScreenerQuestionPresets,
  JobScreenerQuestionValue,
} from "../typings";

export function serializeScreenerQuestions(
  screenerQuestions: JobScreenerQuestionValue[]
) {
  return (
    screenerQuestions
      .filter(
        (
          screenerQuestion
        ): screenerQuestion is JobScreenerQuestionPopulatedValue =>
          !!screenerQuestion.type
      )
      // eslint-disable-next-line array-callback-return
      .map((screenerQuestion) => {
        switch (screenerQuestion.type) {
          case JobScreenerQuestionPresets.HAS_DRIVERS_LICENSE_AND_ACCESS_TO_CAR:
            return {
              type: JobPostingFieldType.HasDriversLicenseAndAccessToCar,
            };
          case "YES_OR_NO":
            return {
              type: JobPostingFieldType.YesOrNo,
              question: screenerQuestion.question,
            };
          default:
            assertNever(screenerQuestion);
        }
      })
  );
}
