import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PulseSurveyOverviewDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PulseSurveyOverviewDataQuery = (
  { __typename?: 'Query' }
  & { getSurveyEngagement: (
    { __typename?: 'SurveyEngagementResponse' }
    & Pick<Types.SurveyEngagementResponse, 'numSent' | 'responseRate'>
    & { reward: (
      { __typename?: 'SurveyReward' }
      & Pick<Types.SurveyReward, 'numPoints' | 'numDrawTickets'>
    ) }
  ), getSurveyAverageScore: (
    { __typename?: 'SurveyAverageScoreResponse' }
    & Pick<Types.SurveyAverageScoreResponse, 'averageScore'>
  ) }
);


export const PulseSurveyOverviewDataDocument = gql`
    query PulseSurveyOverviewData {
  getSurveyEngagement(surveyType: PULSE_CHECK) {
    numSent
    responseRate
    reward {
      numPoints
      numDrawTickets
    }
  }
  getSurveyAverageScore(surveyType: PULSE_CHECK) {
    averageScore
  }
}
    `;

/**
 * __usePulseSurveyOverviewDataQuery__
 *
 * To run a query within a React component, call `usePulseSurveyOverviewDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePulseSurveyOverviewDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePulseSurveyOverviewDataQuery({
 *   variables: {
 *   },
 * });
 */
export function usePulseSurveyOverviewDataQuery(baseOptions?: Apollo.QueryHookOptions<PulseSurveyOverviewDataQuery, PulseSurveyOverviewDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PulseSurveyOverviewDataQuery, PulseSurveyOverviewDataQueryVariables>(PulseSurveyOverviewDataDocument, options);
      }
export function usePulseSurveyOverviewDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PulseSurveyOverviewDataQuery, PulseSurveyOverviewDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PulseSurveyOverviewDataQuery, PulseSurveyOverviewDataQueryVariables>(PulseSurveyOverviewDataDocument, options);
        }
export type PulseSurveyOverviewDataQueryHookResult = ReturnType<typeof usePulseSurveyOverviewDataQuery>;
export type PulseSurveyOverviewDataLazyQueryHookResult = ReturnType<typeof usePulseSurveyOverviewDataLazyQuery>;
export type PulseSurveyOverviewDataQueryResult = Apollo.QueryResult<PulseSurveyOverviewDataQuery, PulseSurveyOverviewDataQueryVariables>;