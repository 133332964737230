/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { compact } from "lodash";

import { Card } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

export interface SurveyEngagementCardProps {
  numSent: number;
  responseRatePercentage: number;
  reward: {
    numPoints?: number | null;
    numDrawTickets?: number | null;
  };
}

export function SurveyEngagementCard({
  numSent,
  responseRatePercentage,
  reward,
}: SurveyEngagementCardProps) {
  return (
    <Card
      css={(theme: AppTheme) =>
        css`
          padding: ${theme.spacing(3)};
          flex-grow: 1;
        `
      }
    >
      <div
        css={(theme: AppTheme) =>
          css`
            margin-bottom: ${theme.spacing(2)};
            display: flex;
            gap: ${theme.spacing(1)};
            align-items: center;
          `
        }
      >
        <Typography variant="h3" fontWeight={700}>
          Engagement
        </Typography>
      </div>
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          justify-content: space-between;
          gap: ${theme.spacing(2)};
        `}
      >
        <div>
          <Typography variant="caption">SENT</Typography>
          <Typography variant="body">{numSent}</Typography>
        </div>
        <div>
          <Typography variant="caption">RESPONSE RATE</Typography>
          <Typography variant="body">{responseRatePercentage}%</Typography>
        </div>
        <div>
          <Typography variant="caption">REWARD</Typography>
          <div
            css={css`
              display: flex;
              gap: 4px;
            `}
          >
            <Typography variant="body">
              {compact([
                reward.numPoints && `${reward.numPoints} points`,
                reward.numDrawTickets &&
                  `${reward.numDrawTickets} ticket${
                    reward.numDrawTickets > 1 ? "s" : ""
                  }`,
              ]).join(" & ")}
            </Typography>
          </div>
        </div>
      </div>
    </Card>
  );
}
