import { invert } from "lodash";

import { RewardsOrganizationOnboardingStepName } from "@rewards-web/shared/graphql-types";

export type OnboardingStepsSupportedByUI = Exclude<
  RewardsOrganizationOnboardingStepName,
  // these steps are deprecated, and no longer used.
  // they exist in the API, but the UI ignores them
  | RewardsOrganizationOnboardingStepName.Champions
  | RewardsOrganizationOnboardingStepName.Recognition
  | RewardsOrganizationOnboardingStepName.Redemption
  | RewardsOrganizationOnboardingStepName.EvvCompliance
>;

export const ONBOARDING_STEP_NAME_BY_KEY: {
  [key in OnboardingStepsSupportedByUI]: string;
} = {
  [RewardsOrganizationOnboardingStepName.Intro]: "Intro",
  [RewardsOrganizationOnboardingStepName.YourProfile]: "Business information",
  [RewardsOrganizationOnboardingStepName.CaregiverInvites]: "Caregiver invites",
  [RewardsOrganizationOnboardingStepName.EmployeeAutoSync]:
    "Sync employee list",
  [RewardsOrganizationOnboardingStepName.ConfigureJobs]: "Create job postings",
  [RewardsOrganizationOnboardingStepName.JobSetting]: "Job settings",
  [RewardsOrganizationOnboardingStepName.ReferralStructure]:
    "Referral structure",
  [RewardsOrganizationOnboardingStepName.BookKickOff]: "Book launch date",
};

export const ONBOARDING_STEP_PATH_BY_KEY: {
  [key in OnboardingStepsSupportedByUI]: string;
} = {
  [RewardsOrganizationOnboardingStepName.Intro]: "intro",
  [RewardsOrganizationOnboardingStepName.YourProfile]: "profile",
  [RewardsOrganizationOnboardingStepName.CaregiverInvites]: "caregiver-invites",
  [RewardsOrganizationOnboardingStepName.EmployeeAutoSync]:
    "employee-auto-sync",
  [RewardsOrganizationOnboardingStepName.ConfigureJobs]: "create-job-postings",
  [RewardsOrganizationOnboardingStepName.JobSetting]: "job-settings",
  [RewardsOrganizationOnboardingStepName.ReferralStructure]:
    "referral-structure",
  [RewardsOrganizationOnboardingStepName.BookKickOff]: "book-kick-off",
};

export const ONBOARDING_STEP_BY_PATH = invert(ONBOARDING_STEP_PATH_BY_KEY) as {
  [path: string]: OnboardingStepsSupportedByUI;
};
