/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  ArrayPath,
  Control,
  Controller,
  FieldValues,
  useFieldArray,
} from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { useFormControlContext } from "@rewards-web/shared/components/form/form-control";
import { IconButton } from "@rewards-web/shared/components/icon-button";
import {
  SearchTextField,
  ValueLabel,
} from "@rewards-web/shared/components/search-text-field";
import { getCharactersRemainingText } from "@rewards-web/shared/lib/characters-remaining-text";
import { AppTheme } from "@rewards-web/shared/style/theme";

import {
  JobScreenerQuestionPopulatedValue,
  JobScreenerQuestionPresets,
  JobScreenerQuestionValue,
} from "../../typings";

const SCREENER_QUESTION_MAX_CHARACTERS = 256;

const SCREENER_QUESTION_PRESETS: Array<{
  type: JobScreenerQuestionPopulatedValue["type"];
  question: string;
}> = [
  {
    type: JobScreenerQuestionPresets.HAS_DRIVERS_LICENSE_AND_ACCESS_TO_CAR,
    question:
      "Do you have a valid driver's license and reliable access to a vehicle?",
  },
  {
    type: "YES_OR_NO",
    question:
      "Do you hold any relevant certifications, such as Home Health Aide (HHA) or Certified Nursing Assistant(CNA)?”",
  },
  {
    type: "YES_OR_NO",
    question:
      "Do you have previous experience in a home care or healthcare setting?",
  },
  {
    type: "YES_OR_NO",
    question: "Have you been fully vaccinated against COVID-19?",
  },
  { type: "YES_OR_NO", question: "Are you at least 18 years old?" },
];

const SCREENER_QUESTION_PRESET_OPTIONS: ValueLabel[] = SCREENER_QUESTION_PRESETS.map(
  (preset) => ({
    value: preset.question,
    label: preset.question,
  })
);

interface JobScreenerQuestionsFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: ArrayPath<T>;
}

export function JobScreenerQuestionsField<T extends FieldValues>({
  control,
  name,
}: JobScreenerQuestionsFieldProps<T>): JSX.Element {
  const { fields, append, remove } = useFieldArray({ control, name });
  const { readOnly } = useFormControlContext();

  return (
    <>
      {((fields as unknown) as (JobScreenerQuestionValue & {
        id: string;
      })[]).map((screenerField, index) => (
        <div
          key={screenerField.id}
          css={css`
            display: flex;
          `}
        >
          <Controller
            control={control}
            name={`${name}.${index}` as any}
            rules={{
              required: "Job applied to is required",
              validate: (value: JobScreenerQuestionValue) => {
                if (
                  value.type === "YES_OR_NO" &&
                  value.question?.length > SCREENER_QUESTION_MAX_CHARACTERS
                ) {
                  return getCharactersRemainingText(
                    value.question,
                    SCREENER_QUESTION_MAX_CHARACTERS
                  );
                }
              },
            }}
            render={({ field, fieldState }) => (
              <SearchTextField
                css={css`
                  flex-grow: 1;
                `}
                name={field.name}
                onBlur={field.onBlur}
                fixLabelToTop
                placeholder="E.g. Do you have a driving license and access to a car?"
                value={(() => {
                  const value = field.value as JobScreenerQuestionValue;

                  if (!value.type) {
                    return null;
                  }

                  if (value.type === "YES_OR_NO") {
                    return value.question;
                  }

                  const matchingPreset = SCREENER_QUESTION_PRESETS.find(
                    (option) => option.type === value.type
                  );

                  if (!matchingPreset) {
                    // should not happen
                    throw new Error("No matching preset");
                  }

                  return matchingPreset.question;
                })()}
                onChange={(value: string) => {
                  if (value === null) {
                    field.onChange({
                      type: null,
                    });
                  } else {
                    const matchingPreset = SCREENER_QUESTION_PRESETS.find(
                      (option) =>
                        option.question === value && option.type !== "YES_OR_NO"
                    );

                    if (matchingPreset) {
                      // non YES_OR_NO preset selected
                      field.onChange({
                        type: matchingPreset.type,
                      });
                    } else {
                      field.onChange({
                        type: "YES_OR_NO",
                        question: value,
                      });
                      // custom question entered
                    }
                  }
                }}
                helperText={
                  (field.value as JobScreenerQuestionValue).type === "YES_OR_NO"
                    ? getCharactersRemainingText(
                        field.value.question ?? "",
                        SCREENER_QUESTION_MAX_CHARACTERS
                      )
                    : undefined
                }
                error={fieldState.error}
                label={`Screening Question ${index + 1}`}
                options={SCREENER_QUESTION_PRESET_OPTIONS}
                allowCustomValues
              />
            )}
          />

          {!readOnly && (
            <IconButton
              css={(theme: AppTheme) => css`
                height: 50px;
                width: 50px;
                margin-left: ${theme.spacing(1)};
                margin-top: ${theme.spacing(0.5)};
              `}
              onClick={() => {
                remove(index);
              }}
              aria-label={"Delete screener question"}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      ))}

      {!readOnly && (
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          label="Add a question"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(3)};
          `}
          onClick={() => {
            append({ type: null } as any);
          }}
        />
      )}
    </>
  );
}
