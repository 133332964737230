/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Card } from "@rewards-web/shared/components/card";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { SurveyAgencyAverageCard } from "../components/survey-agency-average-card";
import { SurveyEngagementCard } from "../components/survey-engagement-card";
import { PULSE_SURVEY_QUESTIONS } from "../constants";
import { usePulseSurveyOverviewDataQuery } from "./pulse-survey-overview-data.generated";
import { SurveyQuestionScoreCard } from "./survey-question-score-card";

export function PulseSurveysPage() {
  const { data, error } = usePulseSurveyOverviewDataQuery({
    onError: reportError,
  });
  const theme = useTheme();

  if (error) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  if (!data) {
    return <PageLoadingState />;
  }

  if (data.getSurveyEngagement.numSent === 0) {
    return (
      <Alert
        severity="info"
        message="No surveys sent yet. Contact the Caribou customer success team to
        enable this feature!"
      />
    );
  }

  return (
    <>
      <Typography
        variant="h2"
        color="textPrimary"
        fontWeight={700}
        marginBottom={theme.spacing(2)}
      >
        Overview
      </Typography>
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          flex-wrap: wrap;
          gap: ${theme.spacing(2)};
          margin-bottom: ${theme.spacing(3)};
        `}
      >
        <SurveyEngagementCard
          numSent={data.getSurveyEngagement.numSent}
          responseRatePercentage={Math.round(
            data.getSurveyEngagement.responseRate * 100
          )} // round to closest percentage
          reward={data.getSurveyEngagement.reward}
        />
        <SurveyAgencyAverageCard
          averageScore={
            Math.round(data.getSurveyAverageScore.averageScore * 100) / 100
          } // round to 2 decimals
        />
      </div>
      <Card
        css={(theme: AppTheme) =>
          css`
            padding: ${theme.spacing(3)};
          `
        }
      >
        <Typography
          variant="h3"
          color="textPrimary"
          fontWeight={700}
          css={(theme: AppTheme) =>
            css`
              margin-bottom: ${theme.spacing(3)};
            `
          }
        >
          Results
        </Typography>
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            gap: ${theme.spacing(3)};
          `}
        >
          {PULSE_SURVEY_QUESTIONS.map((questionType) => (
            <SurveyQuestionScoreCard
              key={questionType}
              questionType={questionType}
            />
          ))}
        </div>
      </Card>
    </>
  );
}
