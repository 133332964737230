/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { useForm, useWatch } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { Typography } from "@rewards-web/shared/components/typography";
import * as GraphQLTypes from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/theme";
import { ApolloGraphQLResponseContext } from "@rewards-web/shared/types/apollo-response-context";

import { ConfirmCandidateEmployeeLinkModal } from "./confirm-candidate-employee-link-modal";
import { useLinkCandidateToEmployeeMutation } from "./link-candidate-to-employee.generated";
import { LinkedEmployeeSearchField } from "./linked-employee-search-field";
import { useCandidateEmployeeLinkOrganizationInfoQuery } from "./organization-info.generated";

interface SetCandidateEmployeeLinkFormProps {
  candidate: Pick<GraphQLTypes.Candidate, "id" | "firstName">;
}

export function SetCandidateEmployeeLinkForm({
  candidate,
}: SetCandidateEmployeeLinkFormProps) {
  const snackbar = useSnackbar();
  const track = useTrack();
  const organizationInfoQuery = useCandidateEmployeeLinkOrganizationInfoQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
  });
  const organizationShortName =
    organizationInfoQuery.data?.getMyRewardsOrganization.shortName;

  const [
    linkCandidateToEmployee,
    { loading: linkingCandidateToEmployee },
  ] = useLinkCandidateToEmployeeMutation();

  const form = useForm<{ linkedEmployeeId: string | null }>({
    defaultValues: {
      linkedEmployeeId: null,
    },
  });
  const employeeId = useWatch({
    control: form.control,
    name: "linkedEmployeeId",
  });

  const [confirmPromptOpen, setConfirmPromptOpen] = useState(false);

  const handleSubmit = () => {
    setConfirmPromptOpen(true);
  };

  const handleConfirm = async () => {
    try {
      const res = await linkCandidateToEmployee({
        variables: {
          candidateId: candidate.id,
          employeeId: employeeId!,
        },
      });

      setConfirmPromptOpen(false);

      snackbar.show({
        severity: "success",
        message: "Candidate successfully linked to employee.",
      });
      track("Candidate and Employee linked", {
        candidateId: candidate.id,
        employeeId: employeeId!,
        requestId: (res.context as ApolloGraphQLResponseContext | undefined)
          ?.requestId,
      });
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  return (
    <>
      <ConfirmCandidateEmployeeLinkModal
        open={confirmPromptOpen}
        confirming={linkingCandidateToEmployee}
        onConfirm={handleConfirm}
        onCancel={() => {
          setConfirmPromptOpen(false);
        }}
        organizationShortName={organizationShortName!}
        candidateId={candidate.id}
        employeeId={employeeId!}
      />

      <Form onSubmit={form.handleSubmit(handleSubmit)}>
        <Typography
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          Once {candidate.firstName} has a Caribou Employee Account created,
          search for it below by their name, email or phone number. This link
          will allow us to notify {organizationShortName} when the referring
          employee should receive referral points.
        </Typography>
        <LinkedEmployeeSearchField
          control={form.control}
          name="linkedEmployeeId"
        />
        <Button color="primary" width="auto" label="Save Link" type="submit" />
      </Form>
    </>
  );
}
