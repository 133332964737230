/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import {
  faAward,
  faCalendar,
  faComments,
  faFaceSmile,
  faHouseHeart,
  faLocationDot,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Typography } from "@rewards-web/shared/components/typography";
import { SurveyQuestionType } from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { AppTheme } from "@rewards-web/shared/style/types";

import { SurveyQuestionTypeSupportedByUI } from "../../constants";

export function QuestionCategory({
  questionType,
}: {
  questionType: SurveyQuestionTypeSupportedByUI;
}) {
  const theme = useTheme();

  const [icon, category] = (() => {
    switch (questionType) {
      case SurveyQuestionType.MeaningfulRecognition:
        return [faAward, "RECOGNITION"];
      case SurveyQuestionType.ManageableWorkload:
        return [faCalendar, "SCHEDULE"];
      case SurveyQuestionType.ReliableTransportation:
        return [faLocationDot, "LOCATION"];
      case SurveyQuestionType.LeadershipSupport:
        return [faComments, "SUPPORT"];
      case SurveyQuestionType.Resources:
        return [faHouseHeart, "JOB SATISFACTION"];
      case SurveyQuestionType.Nps:
        return [faFaceSmile, "NPS"];
      default:
        assertNever(questionType);
    }
  })();

  return (
    <div
      css={(theme: AppTheme) => css`
        display: flex;
        align-items: center;
        gap: ${theme.spacing(0.5)};
        margin-bottom: ${theme.spacing(0.5)};
      `}
    >
      <FontAwesomeIcon icon={icon} color={theme.palette.grey[800]} />
      <Typography
        variant="caption"
        color={theme.palette.grey[800]}
        fontWeight={600}
      >
        {category}
      </Typography>
    </div>
  );
}
