import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RewardsAdminToEditQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type RewardsAdminToEditQuery = (
  { __typename?: 'Query' }
  & { getRewardsAdminById?: Types.Maybe<(
    { __typename?: 'RewardsAdminUser' }
    & Pick<Types.RewardsAdminUser, 'id' | 'firstName' | 'lastName' | 'email' | 'jobTitle' | 'role' | 'active'>
    & { permissions: (
      { __typename?: 'RewardsAdminFullAccessPermissions' }
      & Pick<Types.RewardsAdminFullAccessPermissions, 'type'>
    ) | (
      { __typename?: 'RewardsAdminRestrictedAccessPermissions' }
      & Pick<Types.RewardsAdminRestrictedAccessPermissions, 'type'>
      & { permissionsV2?: Types.Maybe<(
        { __typename?: 'RewardsAdminRestrictedAccessPermissionsMapV2' }
        & { jobs?: Types.Maybe<(
          { __typename?: 'RewardsAdminRestrictedAccessJobsPermissions' }
          & Pick<Types.RewardsAdminRestrictedAccessJobsPermissions, 'permissionLevel'>
        )>, candidates?: Types.Maybe<(
          { __typename?: 'RewardsAdminRestrictedAccessCandidatesPermissions' }
          & Pick<Types.RewardsAdminRestrictedAccessCandidatesPermissions, 'permissionLevel'>
        )>, redemptions?: Types.Maybe<(
          { __typename?: 'RewardsAdminRestrictedAccessRedemptionsPermissions' }
          & Pick<Types.RewardsAdminRestrictedAccessRedemptionsPermissions, 'permissionLevel'>
        )>, recognitionPoints?: Types.Maybe<(
          { __typename?: 'RewardsAdminRestrictedAccessRecognitionPointsPermissions' }
          & Pick<Types.RewardsAdminRestrictedAccessRecognitionPointsPermissions, 'permissionLevel' | 'canOverrideRecognitionCategoryDefaultAmounts' | 'canOverrideBudget'>
        )>, employees?: Types.Maybe<(
          { __typename?: 'RewardsAdminRestrictedAccessEmployeesPermissions' }
          & Pick<Types.RewardsAdminRestrictedAccessEmployeesPermissions, 'permissionLevel'>
        )>, administrators?: Types.Maybe<(
          { __typename?: 'RewardsAdminRestrictedAccessAdministratorsPermissions' }
          & Pick<Types.RewardsAdminRestrictedAccessAdministratorsPermissions, 'permissionLevel'>
        )> }
      )> }
    ), branches: Array<(
      { __typename?: 'RewardsOrganizationBranch' }
      & Pick<Types.RewardsOrganizationBranch, 'id' | 'name'>
    )>, automaticRecognitionBudgetConfig?: Types.Maybe<(
      { __typename?: 'RewardsAdminAutomaticRecognitionBudgetConfig' }
      & Pick<Types.RewardsAdminAutomaticRecognitionBudgetConfig, 'enabled' | 'defaultAmount'>
    )> }
  )> }
);


export const RewardsAdminToEditDocument = gql`
    query RewardsAdminToEdit($id: ID!) {
  getRewardsAdminById(id: $id) {
    id
    firstName
    lastName
    email
    jobTitle
    role
    permissions {
      ... on RewardsAdminFullAccessPermissions {
        type
      }
      ... on RewardsAdminRestrictedAccessPermissions {
        type
        permissionsV2 {
          jobs {
            permissionLevel
          }
          candidates {
            permissionLevel
          }
          redemptions {
            permissionLevel
          }
          recognitionPoints {
            permissionLevel
            canOverrideRecognitionCategoryDefaultAmounts
            canOverrideBudget
          }
          employees {
            permissionLevel
          }
          administrators {
            permissionLevel
          }
        }
      }
    }
    active
    branches {
      id
      name
    }
    automaticRecognitionBudgetConfig {
      enabled
      defaultAmount
    }
  }
}
    `;

/**
 * __useRewardsAdminToEditQuery__
 *
 * To run a query within a React component, call `useRewardsAdminToEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardsAdminToEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsAdminToEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRewardsAdminToEditQuery(baseOptions: Apollo.QueryHookOptions<RewardsAdminToEditQuery, RewardsAdminToEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RewardsAdminToEditQuery, RewardsAdminToEditQueryVariables>(RewardsAdminToEditDocument, options);
      }
export function useRewardsAdminToEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RewardsAdminToEditQuery, RewardsAdminToEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RewardsAdminToEditQuery, RewardsAdminToEditQueryVariables>(RewardsAdminToEditDocument, options);
        }
export type RewardsAdminToEditQueryHookResult = ReturnType<typeof useRewardsAdminToEditQuery>;
export type RewardsAdminToEditLazyQueryHookResult = ReturnType<typeof useRewardsAdminToEditLazyQuery>;
export type RewardsAdminToEditQueryResult = Apollo.QueryResult<RewardsAdminToEditQuery, RewardsAdminToEditQueryVariables>;