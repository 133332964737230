import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
export type JobPostingEditDetailsFragment = (
  { __typename?: 'JobPosting' }
  & Pick<Types.JobPosting, 'id' | 'title' | 'hoursRequired' | 'ratesOfPay' | 'geography' | 'descriptionHTML' | 'candidateAlertEmail' | 'customizedApplicantEmailMessage' | 'customizedApplicantSMSMessage' | 'interviewBookingLink' | 'mustUploadResumeBeforeBookingInterview'>
  & { branch?: Types.Maybe<(
    { __typename?: 'RewardsOrganizationBranch' }
    & Pick<Types.RewardsOrganizationBranch, 'id' | 'name'>
  )>, applicationFields: Array<(
    { __typename?: 'JobPostingSystemRequiredField' }
    & Pick<Types.JobPostingSystemRequiredField, 'id' | 'type'>
  ) | (
    { __typename?: 'JobPostingSystemMaybeOptionalField' }
    & Pick<Types.JobPostingSystemMaybeOptionalField, 'id' | 'type' | 'optional'>
  ) | (
    { __typename?: 'JobPostingYesNoField' }
    & Pick<Types.JobPostingYesNoField, 'id' | 'type' | 'optional' | 'question'>
  )> }
);

export const JobPostingEditDetailsFragmentDoc = gql`
    fragment JobPostingEditDetails on JobPosting {
  id
  branch {
    id
    name
  }
  title
  hoursRequired
  ratesOfPay
  geography
  descriptionHTML
  applicationFields {
    ... on JobPostingSystemRequiredField {
      id
      type
    }
    ... on JobPostingSystemMaybeOptionalField {
      id
      type
      optional
    }
    ... on JobPostingYesNoField {
      id
      type
      optional
      question
    }
  }
  candidateAlertEmail
  customizedApplicantEmailMessage
  customizedApplicantSMSMessage
  interviewBookingLink
  mustUploadResumeBeforeBookingInterview
}
    `;