/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faMessageLines } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { SurveyQuestionType } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { SurveyQuestionTypeSupportedByUI } from "../../constants";
import { usePulseSurveyQuestionScoreCardDataQuery } from "./pulse-survey-question-score-card-data.generated";
import { QuestionCategory } from "./question-category";
import { QuestionText } from "./question-text";

export interface SurveyQuestionScoreCardProps {
  questionType: SurveyQuestionTypeSupportedByUI;
}

export function SurveyQuestionScoreCard({
  questionType,
}: SurveyQuestionScoreCardProps) {
  const { data, error } = usePulseSurveyQuestionScoreCardDataQuery({
    variables: {
      questionType,
    },
    onError: reportError,
  });

  const theme = useTheme();

  if (error) {
    return null;
  }

  const averageScore = (() => {
    if (!data) return "-";

    // round to 2 decimal places
    return Math.round(data.getSurveyQuestionResults.averageScore * 100) / 100;
  })();

  return (
    <Card
      variant="outlined"
      css={(theme: AppTheme) =>
        css`
          padding: ${theme.spacing(2)};
          flex-grow: 1;
          box-sizing: border-box;
          // ensure 3 cards per row, at the most
          width: calc((100% - (${theme.spacing(3)} * 2)) / 3);
          min-width: 300px;
        `
      }
    >
      <QuestionCategory questionType={questionType} />
      <QuestionText questionType={questionType} />
      <div
        css={css`
          display: flex;
          margin: ${theme.spacing(2)} 0;
        `}
      >
        <Typography variant="h2" fontWeight={700}>
          {averageScore}
        </Typography>
        <Typography
          variant="subtitle"
          color={theme.palette.grey[800]}
          css={css`
            align-self: flex-end;
          `}
        >
          /{questionType === SurveyQuestionType.Nps ? 10 : 5}
        </Typography>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: ${theme.spacing(1)};
        `}
      >
        <Button
          variant="outlined"
          size="small"
          width="auto"
          linkTo="" // TODO
          label="View Details"
        />
        {questionType !== SurveyQuestionType.Nps && (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: ${theme.spacing(0.5)};
            `}
          >
            <FontAwesomeIcon
              icon={faMessageLines}
              color={theme.palette.grey[800]}
            />
            <Typography variant="body" color={theme.palette.grey[800]}>
              {data?.getSurveyQuestionResults.numComments ?? "-"}
            </Typography>
          </div>
        )}
      </div>
    </Card>
  );
}
