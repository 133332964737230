/** @jsxImportSource @emotion/react */
import { ApolloQueryResult } from "@apollo/client";
import { css } from "@emotion/react";

import { Button } from "@rewards-web/shared/components/button";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { getPrizeTierNamewithoutMedal } from "../../lib";
import {
  WinnersToApproveQuery,
  WinnersToApproveQueryVariables,
} from "../winners-to-approve.generated";
import { useRedrawWinnerMutation } from "./redraw-winner.generated";

type RemoveWinner = {
  winnerId: string;
  firstName: string;
  lastName: string;
  prizeValue: string;
  prizeTierIndex?: number;
};
interface RemoveWinnersModalProps {
  drawId: string;
  removeWinner?: RemoveWinner;
  open: boolean;
  onClose(): void;
  winnersToApproveRefetch: (
    variables?: Partial<WinnersToApproveQueryVariables>
  ) => Promise<ApolloQueryResult<WinnersToApproveQuery>>;
}

export function RemoveWinnersModal({
  drawId,
  removeWinner,
  open,
  onClose,
  winnersToApproveRefetch,
}: RemoveWinnersModalProps) {
  const snackbar = useSnackbar();

  const [redrawWinner, { loading }] = useRedrawWinnerMutation();
  const track = useTrack();

  const handleConfirm = async () => {
    try {
      if (!removeWinner) {
        // this shouldn't happen
        throw new Error("You should select a winner");
      }
      await redrawWinner({
        variables: { drawId, winnerId: removeWinner.winnerId },
      });
      track("Confirmed the redraw for the winner", {
        drawId,
        removedWinner: removeWinner?.winnerId,
      });
      snackbar.show({
        severity: "success",
        message: "Winner has been successfully removed.",
      });
      await winnersToApproveRefetch();
      onClose();
    } catch (error) {
      reportError(error);
      if (
        typeof error === "object" &&
        Array.isArray((error as any)?.graphQLErrors)
      ) {
        const message = (error as any).graphQLErrors[0]?.message;
        /**
         * This occurs when the configured number of winners is 1.
         * We won't know it's the last winner (since enoughWinnersDrawn is always true), resulting in this error.
         */

        if (
          message ===
          "Cannot remove the last winner. A draw must have at least one winner."
        ) {
          snackbar.show({
            severity: "error",
            message: message,
          });
        } else {
          snackbar.show({
            severity: "error",
            message: "An unexpected error occurred. Please try again later.",
          });
        }
      } else {
        snackbar.show({
          severity: "error",
          message: "An unexpected error occurred. Please try again later.",
        });
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      width="580px"
      disableBackdropClick={loading}
    >
      <ModalContent>
        <Typography
          variant="h2"
          color="textPrimary"
          css={(theme: AppTheme) =>
            css`
              margin-bottom: ${theme.spacing(2)};
            `
          }
        >
          Reject winner?
        </Typography>
        <Typography
          variant="body"
          color="grey.800"
          css={(theme: AppTheme) =>
            css`
              margin-bottom: ${theme.spacing(1)};
            `
          }
        >
          Are you sure you want to remove {removeWinner?.firstName}{" "}
          {removeWinner?.lastName} from the{" "}
          {getPrizeTierNamewithoutMedal(removeWinner?.prizeTierIndex || 0)}{" "}
          valued at {removeWinner?.prizeValue}?
          <strong> This action is final and cannot be reversed.</strong>
          <br />
          Please confirm if you wish to proceed.
        </Typography>
      </ModalContent>
      <ModalActions>
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            justify-content: flex-end;
            gap: ${theme.spacing(2)};
          `}
        >
          <Button
            variant="outlined"
            width="auto"
            size="large"
            label="Cancel"
            onClick={() => {
              track("Canceled the redraw for the winner", {
                drawId,
                intendedRemovedWinnerId: removeWinner?.winnerId,
              });
              onClose();
            }}
            disabled={loading}
          />
          <Button
            width="auto"
            size="large"
            color="primary"
            label="Confirm"
            onClick={handleConfirm}
            loading={loading}
          />
        </div>
      </ModalActions>
    </Modal>
  );
}
