/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";

import { Card } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

export interface SurveyAgencyAverageCardProps {
  averageScore: number;
}

export function SurveyAgencyAverageCard({
  averageScore,
}: SurveyAgencyAverageCardProps) {
  const theme = useTheme();
  return (
    <Card
      css={(theme: AppTheme) =>
        css`
          padding: ${theme.spacing(3)};
          flex-grow: 1;
        `
      }
    >
      <div
        css={(theme: AppTheme) =>
          css`
            display: flex;
            margin-bottom: ${theme.spacing(2)};
            align-items: center;
            gap: ${theme.spacing(1)};
          `
        }
      >
        <Typography variant="h3" fontWeight={700}>
          Agency average
        </Typography>
      </div>
      <div
        css={css`
          display: flex;
          align-self: flex-end;
        `}
      >
        <Typography variant="h2" fontWeight={700}>
          {averageScore}
        </Typography>
        <Typography
          variant="subtitle"
          color={theme.palette.grey[800]}
          css={css`
            align-self: flex-end;
          `}
        >
          /5
        </Typography>
      </div>
    </Card>
  );
}
