import { ApolloError } from "@apollo/client";

import {
  Maybe,
  RewardsAdminFullAccessPermissions,
  RewardsAdminPermissionsType,
  RewardsAdminRestrictedAccessPermissions,
  RewardsAdminRestrictedAccessPermissionsMapV2,
  RewardsAdminUser,
} from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";

import { useCognitoAuth } from "../../cognito-auth";
import { useRole } from "../../role";
import { useMyAdminPermissionsQuery } from "./my-admin-permissions.generated";

type RequiredPermissionsQuery = {
  getMyRewardsAdminUser: {
    __typename?: "RewardsAdminUser";
  } & Pick<RewardsAdminUser, "id"> & {
      permissions:
        | ({ __typename?: "RewardsAdminFullAccessPermissions" } & Pick<
            RewardsAdminFullAccessPermissions,
            "type"
          >)
        | ({ __typename?: "RewardsAdminRestrictedAccessPermissions" } & Pick<
            RewardsAdminRestrictedAccessPermissions,
            "type"
          > & {
              permissionsV2?: Maybe<
                {
                  __typename?: "RewardsAdminRestrictedAccessPermissionsMapV2";
                } & Pick<
                  RewardsAdminRestrictedAccessPermissionsMapV2,
                  | "jobs"
                  | "candidates"
                  | "redemptions"
                  | "recognitionPoints"
                  | "employees"
                  | "administrators"
                >
              >;
            });
    };
};

export function usePermissionsQuery(): {
  data: RequiredPermissionsQuery | undefined;
  loading: boolean;
  error: ApolloError | undefined;
} {
  const { userId } = useCognitoAuth();
  const role = useRole();
  const permissionsQuery = useMyAdminPermissionsQuery({
    fetchPolicy: "cache-first",
    onError: reportError,
    pollInterval: 86400000, // refresh once per 24 hours
    skip: role.data?.role !== "admin",
  });

  const data = ((): undefined | RequiredPermissionsQuery => {
    if (!role.data) {
      return undefined;
    }

    if (role.data.role === "admin") {
      return (permissionsQuery.data as RequiredPermissionsQuery) ?? undefined;
    }

    return {
      getMyRewardsAdminUser: {
        id: userId!,
        permissions: { type: RewardsAdminPermissionsType.FullAccess },
      },
    };
  })();

  return {
    data,
    loading: permissionsQuery.loading || role.loading,
    error: role.error || permissionsQuery.error,
  };
}
