import * as GraphQLTypes from "@rewards-web/shared/graphql-types";

import { JobPostingEditDetailsFragment } from "../graphql/job-posting-fragment.generated";
import {
  JobScreenerQuestionValue,
  JobScreenerQuestionPresets,
} from "../typings";

export function getJobPostingDefaultValues(
  jobPosting: JobPostingEditDetailsFragment
): {
  jobTitle: string;
  branchId: string | null;
  rateOfPay: string;
  location: string;
  hoursRequired: string;
  jobDescription: string;
  screenerQuestions: JobScreenerQuestionValue[];
  candidateAlertEmail: string;
  customizedEmailMessage: string;
  customizedSMSMessage: string;
  interviewBookingLink: string;
  customizedSMSMessageChecked: boolean;
  customizedEmailMessageChecked: boolean;
  interviewBookingLinkChecked: boolean;
  mustUploadResumeBeforeBookingInterview: "YES" | "NO";
} {
  return {
    jobTitle: jobPosting.title,
    branchId: jobPosting.branch?.id ?? null,
    rateOfPay: jobPosting.ratesOfPay ?? "",
    location: jobPosting.geography,
    hoursRequired: jobPosting.hoursRequired,
    jobDescription: jobPosting.descriptionHTML,
    candidateAlertEmail: jobPosting.candidateAlertEmail ?? "",
    customizedEmailMessage: jobPosting.customizedApplicantEmailMessage ?? "",
    customizedSMSMessage: jobPosting.customizedApplicantSMSMessage ?? "",
    interviewBookingLink: jobPosting.interviewBookingLink ?? "",
    mustUploadResumeBeforeBookingInterview: jobPosting.mustUploadResumeBeforeBookingInterview
      ? "YES"
      : "NO",
    customizedEmailMessageChecked: Boolean(
      jobPosting.customizedApplicantEmailMessage
    ),
    customizedSMSMessageChecked: Boolean(
      jobPosting.customizedApplicantSMSMessage
    ),
    interviewBookingLinkChecked: Boolean(jobPosting.interviewBookingLink),
    screenerQuestions: jobPosting.applicationFields
      .filter(
        (field) =>
          field.type ===
            GraphQLTypes.JobPostingFieldType.HasDriversLicenseAndAccessToCar ||
          field.type === GraphQLTypes.JobPostingFieldType.YesOrNo
      )
      .map((field) => {
        switch (field.type) {
          case GraphQLTypes.JobPostingFieldType.HasDriversLicenseAndAccessToCar:
            return {
              type:
                JobScreenerQuestionPresets.HAS_DRIVERS_LICENSE_AND_ACCESS_TO_CAR,
            };

          default:
            return {
              type: "YES_OR_NO",
              question: (field as GraphQLTypes.JobPostingYesNoField).question,
            };
        }
      }),
  };
}
