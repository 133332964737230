/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useForm } from "react-hook-form";

import { Form } from "@rewards-web/shared/components/form";
import { Typography } from "@rewards-web/shared/components/typography";
import { RewardsOrganizationOnboardingStepName } from "@rewards-web/shared/graphql-types";
import { useNavigationBlockingPrompt } from "@rewards-web/shared/hooks/use-navigation-blocking-prompt";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { FormFieldOutline } from "../../../../../../shared/components/content-container";
import { MyBranchesData } from "../../../../../../shared/modules/branches/use-my-branches";
import { JobBranchField } from "../../../../../../shared/modules/jobs/fields/job-branch-field";
import { JobCandidateAlertEmailField } from "../../../../../../shared/modules/jobs/fields/job-candidate-alert-email-field";
import { JobCustomEmailField } from "../../../../../../shared/modules/jobs/fields/job-custom-email-field";
import { JobCustomSMSField } from "../../../../../../shared/modules/jobs/fields/job-custom-sms-field";
import { JobDescriptionField } from "../../../../../../shared/modules/jobs/fields/job-description-field";
import { JobHoursRequiredField } from "../../../../../../shared/modules/jobs/fields/job-hours-required-field";
import { JobInterviewBookingLinkField } from "../../../../../../shared/modules/jobs/fields/job-interview-booking-link-field";
import { JobLocationField } from "../../../../../../shared/modules/jobs/fields/job-location-field";
import { JobRateOfPayField } from "../../../../../../shared/modules/jobs/fields/job-rate-of-pay-field";
import { JobScreenerQuestionsField } from "../../../../../../shared/modules/jobs/fields/job-screener-questions";
import { JobTitleField } from "../../../../../../shared/modules/jobs/fields/job-title-field";
import { useCreateJobPostingMutation } from "../../../../../../shared/modules/jobs/graphql/create-job-posting.generated";
import { serializeJobFieldConfiguration } from "../../../../../../shared/modules/jobs/lib/serialize-job-field-configuration";
import { JobScreenerQuestionValue } from "../../../../../../shared/modules/jobs/typings";
import { AdminOnboardingCard } from "../../../components/onboarding-card/onboarding-card";
import { AdminOnboardingCardContent } from "../../../components/onboarding-card/onboarding-card-content";
import { AdminOnboardingCardHeader } from "../../../components/onboarding-card/onboarding-card-header";
import { AdminOnboardingStepActions } from "../../../components/step-actions";
import { useOnboardingState } from "../../../hooks/use-onboarding-state";

interface OnboardingAddJobFormValues {
  branchId: string | null;
  jobTitle: string;
  rateOfPay: string;
  location: string;
  hoursRequired: string;
  jobDescription: string;
  screenerQuestions: JobScreenerQuestionValue[];
  candidateAlertEmail: string;
  customizedEmailMessage: string;
  customizedSMSMessage: string;
  customizedSMSMessageChecked: boolean;
  customizedEmailMessageChecked: boolean;
  interviewBookingLink: string;
  interviewBookingLinkChecked: boolean;
  mustUploadResumeBeforeBookingInterview: "YES" | "NO";
}

export interface OnboardingAddJobProps {
  onAddedJob(): void;
  branchesData: MyBranchesData;
}

export function OnboardingAddJob({
  onAddedJob,
  branchesData,
}: OnboardingAddJobProps): JSX.Element {
  const snackbar = useSnackbar();
  const [createJobPosting] = useCreateJobPostingMutation();
  const onboardingState = useOnboardingState();

  const submit = async (values: OnboardingAddJobFormValues) => {
    try {
      await createJobPosting({
        variables: {
          title: values.jobTitle,
          branchId: values.branchId || null,
          hoursRequired: values.hoursRequired,
          ratesOfPay: values.rateOfPay || null,
          geography: values.location,
          descriptionHTML: values.jobDescription,
          candidateAlertEmail: values.candidateAlertEmail,
          customizedApplicantEmailMessage: values.customizedEmailMessageChecked
            ? values.customizedEmailMessage
            : undefined,
          customizedApplicantSMSMessage: values.customizedSMSMessageChecked
            ? values.customizedSMSMessage
            : undefined,
          fieldConfiguration: serializeJobFieldConfiguration(
            values.screenerQuestions
          ),
          interviewBookingLink: values.interviewBookingLinkChecked
            ? {
                url: values.interviewBookingLink,
                mustUploadResumeBeforeBookingInterview:
                  values.mustUploadResumeBeforeBookingInterview === "YES",
              }
            : undefined,
        },
      });
      onboardingState.recordCompletedOnboardingStep(
        RewardsOrganizationOnboardingStepName.ConfigureJobs
      );

      onAddedJob();
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = useForm<OnboardingAddJobFormValues>({
    defaultValues: {
      jobTitle: "",
      branchId:
        branchesData.getMyRewardsAdminUser?.branches.length === 1
          ? branchesData.getMyRewardsAdminUser?.branches[0].id
          : null,
      rateOfPay: "",
      location: "",
      hoursRequired: "",
      jobDescription: "",
      screenerQuestions: [],
      candidateAlertEmail: "",
      customizedEmailMessage: "",
      customizedSMSMessage: "",
      customizedEmailMessageChecked: false,
      customizedSMSMessageChecked: false,
      interviewBookingLink: "",
      interviewBookingLinkChecked: false,
      mustUploadResumeBeforeBookingInterview: "NO",
    },
  });

  useNavigationBlockingPrompt(
    "Are you sure you want to leave this page? You will lose all unsaved changes.",
    isDirty
  );

  const organizationHasBranches =
    branchesData.getMyRewardsOrganization.branches.length > 0;

  return (
    <Form onSubmit={handleSubmit(submit)} submitting={isSubmitting}>
      <AdminOnboardingCard>
        <AdminOnboardingCardHeader title="💼️ Create Job Postings" />

        <AdminOnboardingCardContent>
          <div
            css={(appTheme: AppTheme) => css`
              margin-bottom: ${appTheme.spacing(3)};
            `}
          >
            <Typography color="textSecondary" variant="body">
              Add your job postings for your caregivers to share with their
              friends and earn rewards through the referral program.
            </Typography>
            <Typography color="textSecondary" variant="body">
              Pro-tip: Assign one recruiter to contact candidates within 24
              hours. Caribou will help you get referrals but contacting those
              candidates is up to you.
            </Typography>
          </div>
          {organizationHasBranches && (
            <JobTitleField control={control} name="jobTitle" />
          )}
          <div
            css={(theme: AppTheme) => css`
              display: grid;
              grid-template-columns:
                calc(50% - ${theme.spacing(1)})
                calc(50% - ${theme.spacing(1)});
              grid-column-gap: ${theme.spacing(2)};
            `}
          >
            {organizationHasBranches ? (
              <JobBranchField control={control} name="branchId" />
            ) : (
              <JobTitleField control={control} name="jobTitle" />
            )}
            <JobLocationField control={control} name="location" />
            <JobRateOfPayField control={control} name="rateOfPay" />
            <JobHoursRequiredField control={control} name="hoursRequired" />
          </div>

          <FormFieldOutline
            label="Description"
            name="job-description"
            divider={false}
          >
            <JobDescriptionField control={control} name="jobDescription" />
          </FormFieldOutline>

          <FormFieldOutline
            label="Screening Questions"
            name="screener-questions"
            divider={false}
            description={`Feel free to add your own custom questions. All questions must be formatted as yes-no questions, as answers are not customizable.`}
          >
            <JobScreenerQuestionsField
              control={control}
              name="screenerQuestions"
            />
          </FormFieldOutline>

          <FormFieldOutline
            label="Notification preferences"
            name="alert-preferences"
            divider={false}
            description="Who should receive email alerts about new candidates?"
          >
            <JobCandidateAlertEmailField
              control={control}
              name="candidateAlertEmail"
              emailAutocompleteOptions={[]}
            />
          </FormFieldOutline>

          <FormFieldOutline
            label="Customize"
            name="customization"
            divider={false}
          >
            <JobCustomEmailField
              name="customizedEmailMessage"
              checkBoxName="customizedEmailMessageChecked"
              control={control}
            />
            <JobCustomSMSField
              name="customizedSMSMessage"
              checkBoxName="customizedSMSMessageChecked"
              control={control}
            />
            <JobInterviewBookingLinkField
              name="interviewBookingLink"
              checkBoxName="interviewBookingLinkChecked"
              mustUploadResumeFieldName="mustUploadResumeBeforeBookingInterview"
              control={control}
            />
          </FormFieldOutline>
        </AdminOnboardingCardContent>
      </AdminOnboardingCard>
      <AdminOnboardingStepActions submitButtonLabel="Save and Continue" />
    </Form>
  );
}
