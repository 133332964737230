import { flatten } from "lodash";

import {
  RewardsAdminPermissions,
  RewardsAdminPermissionsType,
} from "@rewards-web/shared/graphql-types";

import {
  ADMIN_SIDE_MENU_PAGE_ORDER,
  ADMIN_PAGE_PATH_BY_NAME,
  ADMIN_PERMISSION_KEY_BY_PAGE,
  AdminPageName,
} from "../../../shared/constants/admin-page";
import { hasPermission } from "../../../shared/modules/permissions/hooks/lib";

export function getDefaultPath(
  permissions: RewardsAdminPermissions,
  launched: boolean
): string {
  if (
    !launched &&
    permissions.type === RewardsAdminPermissionsType.FullAccess
  ) {
    return ADMIN_PAGE_PATH_BY_NAME[AdminPageName.onboarding];
  }

  // find the first path that the user has access to in the navigation bar
  const firstPage = flatten(ADMIN_SIDE_MENU_PAGE_ORDER).find((page) =>
    hasPermission(permissions, "read", ADMIN_PERMISSION_KEY_BY_PAGE[page])
  );

  return firstPage ? ADMIN_PAGE_PATH_BY_NAME[firstPage] : "/forbidden";
}
