/** @jsxImportSource @emotion/react */
import { useState } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Confetti } from "@rewards-web/shared/components/confetti";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { RewardsOrganizationOnboardingStepName } from "@rewards-web/shared/graphql-types";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";

import { useOnboardingState } from "../../hooks/use-onboarding-state";
import { useSaveLaunchDateMutation } from "./book-kick-off-data.generated";
import { BookKickoffCompleted } from "./book-kickoff-completed";
import { BookKickoffForm, BookKickoffFormValues } from "./book-kickoff-form";

export function OnboardingBookKickoff() {
  const {
    completedSteps,
    orderedSteps,
    loading: onboardingStateLoading,
    error: onboardingStateError,
    recordCompletedOnboardingStep,
  } = useOnboardingState();

  const [saveLaunchDate] = useSaveLaunchDateMutation();
  const [isShowingConfetti, setIsShowingConfetti] = useState(false);

  const snackbar = useSnackbar();

  const onSubmit = async (values: BookKickoffFormValues) => {
    try {
      await saveLaunchDate({
        variables: { onboardingTargetLaunchDate: values.targetLaunchDate },
      });
      await recordCompletedOnboardingStep(
        RewardsOrganizationOnboardingStepName.BookKickOff
      );
      setIsShowingConfetti(true);
    } catch (e) {
      snackbar.show({
        severity: "error",
        message: "An unexpected error has occurred. Please try again later.",
      });
      reportError(e);
    }
  };

  if (onboardingStateLoading) {
    return <PageLoadingState />;
  }

  if (onboardingStateError) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  return (
    <>
      <Confetti active={isShowingConfetti} />
      {completedSteps.has(RewardsOrganizationOnboardingStepName.BookKickOff) ? (
        <BookKickoffCompleted
          someStepsNotCompleted={orderedSteps.some(
            (step) => !completedSteps.has(step)
          )}
        />
      ) : (
        <BookKickoffForm onSubmit={onSubmit} />
      )}
    </>
  );
}
